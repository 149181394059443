@import 'scss/vendors/_include-media';

.guest-app {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-image: url("../../assets/login_background_image.jpg");
    background: #f6f8fa;
    background-position: center;
    background-size: cover;
}