.ant-form .ant-form-item .ant-form-item-label,
.ant-form .ant-form-item .ant-form-item-control {
    flex: none !important;
}

// .ant-form {
//     color: $dark-grey-wp;
// }

// .ant-form-item-label>label,
// .label-text {
//     text-transform: uppercase;
//     font-weight: 600;
//     margin-bottom: 3px !important;
//     color: $wp-body-black;
// }

// .label-text {
//     display: inline-block;
//     font-size: 12px;
//     margin-bottom: 6px !important;
// }

// .ant-input {
//     // font-weight: 400;
//     // border-radius: 4px;
//     // border-width: 1px;
//     // border: 1px solid #c5c5c5;
//     // box-shadow: 0 0px 0px rgba(0, 0, 0, .13), 0 0 0 0px rgba(0, 0, 0, .07) !important;
//     // outline: aliceblue;
    
//     // &:focus {
//     //     border-color: $button-bg-hover-wp;
//     // }

//     &.ant-input-disabled{
//         color: rgba(0, 0, 0, 0.25);
//         // background-color: $light-white-wp;
//         border-color: #c5c5c5;
//         opacity: 1;

//         &:hover {
//             border-color: #c5c5c5;
//         }
//     }
// }

// body .ant-input-affix-wrapper {
//     border-radius: 4px !important;
//     border-width: 1px;
//     overflow: hidden;
//     border: 1px solid #c3c4c7;

//     .ant-input {
//         border: none !important;
//     }
// }

// .ant-form-item {
//     @include media('<tablet') {
//         margin-bottom: 5px;
//     }
// }

.ant-form-item-with-help .ant-form-item-explain {
    font-size: 12px;
    line-height: 2;
    margin-left: 3px;
    text-align: left;
}

body .ant-input-search {
    .ant-input-group-addon {
        display: none;
    }

    .ant-input {
        border-color: transparent !important;
    }
}

// .filter-search {
//     .anticon-close-circle {

//         &:hover {
//             color: lighten($grey, 20%);
//         }
//     }
// }

.ant-input-search {

    .ant-input-prefix {
        font-size: 20px;
        // color: $primary-color;
        margin-right: 8px;
    }

    &.filter-search {
        width: 100% !important;

        @include media('>=tablet') {
            width: 301px !important;
        }

        &.autocomplete{
            width: 100% !important;
            
            @include media('>=tablet') {
                width: 100% !important;
            }
        }
    }
}

// .ant-checkbox-wrapper {
//     font-size: 12px;
//     text-transform: uppercase;
//     color: $charleston-green;
// }

// .radio-dark {
//     .ant-radio-wrapper {
//         color: $charleston-green;

//         .ant-radio-inner {
//             border-color: $charleston-green;
//             border-width: 1px;
//         }

//         &:hover {
//             color: $charleston-green;

//             .ant-radio-inner {
//                 border-color: $charleston-green;
//             }
//         }

//         .ant-radio {

//             &.ant-radio-checked {
//                 .ant-radio-inner {
//                     border-width: 2px;
//                 }
//             }
//         }
//     }
// }

.ant-radio-wrapper {
    transition: color 0.2s ease-in-out;

    // &:hover {
    //     color: $white;

    //     .ant-radio-inner {
    //         border-color: $white;
    //     }
    // }

    // .ant-radio {

    //     .ant-radio-input {

    //         &:focus+.ant-radio-inner {
    //             box-shadow: none;
    //         }
    //     }

    //     &.ant-radio-checked {
    //         .ant-radio-inner {
    //             border-color: $primary-color !important;
    //         }
    //     }
    // }

    // .ant-radio-inner {
    //         border-color: $lighten-gray;
    // }
}

.radio-main {
    padding: 11px 7px 0;
}

// .radio-modal {

//     .ant-radio-wrapper {
//         font-size: 14px !important;
//         // color: $charleston-green !important;

//         .ant-radio-inner {
//             border-color: rgba(34, 43, 51, 0.6) !important;
//         }

//         &:hover {
//             color: inherit;

//             .ant-radio-inner {
//                 border-color: inherit;
//             }
//         }
//     }
// }

.ant-select,
.ant-select-item,
.ant-select-selection-item {
    text-align: left;
    // font-weight: 600;
    // color: $charleston-green;
}


.ant-select-selector {

    .page-content & {
        width: calc(100% - 4px) !important;
    }

}

.ant-select-selector,
.select-model-modal-button {
    .page-content & {
        margin-left: 2px;
        margin-right: 2px;
    }
}

.ant-input-password-icon.anticon {
    font-size: 18px;
    color: inherit;
}

// .ant-switch {
//     background-image: linear-gradient(to right, #F34246, #F34246);
// }

// .ant-switch-checked {
//     background: $green-2 !important;
// }

// .ant-picker-header {

//     .ant-picker-header-view {
//         color: $primary-color;
//     }

// }

// .ant-picker-content {

// // th,
// //     td {
// //         color: $charleston-green;
// //     }
// // }

// .ant-picker-time-panel-cell-inner {
//     color: $charleston-green !important;
// }

.ant-picker {
    width: 100%;
}

// .ant-select-item-option-disabled {
//     color: rgba(0, 0, 0, 0.25) !important;
//     cursor: not-allowed;
// }

// .ant-picker{
//     border: 1px solid #c5c5c5;
// }

.field-group{

    .field-date{
        width: 50%;
    }

}

.ant-input-disabled{
    color: var(--color-text) !important;
}

.field-modal-text{
    display: flex;
    justify-content: space-between;
    align-items: center;

    .ant-btn{
        border: none;
        // padding: 0;
        // border: 1px solid #c5c5c5;

        &:hover{
            border: 0px solid #c5c5c5;
        }
    }
}

.ant-modal-content{
    .ant-modal-footer{
        display: flex;
        justify-content: space-between;
        margin-top: 25px !important;
    }
}
