.page-content{

    .ant-page-header-heading-left {
        margin: 0;
    }

    .ant-page-header-heading-title {
        margin-right: 12px;
        margin-bottom: 0;
        color: #3c434a;
        font-weight: 600;
        font-size: 15px;
        line-height: 15px;
    }
    
    .ant-page-header-back {
        margin-right: 5px;
    }
}