@import 'scss/vendors/include-media';
@import 'scss/base/variables';


.sign-in-form {
    width: 100%;
    max-width: 380px;
    padding: 8px 40px 30px !important;
    // background: #001529;
    border-radius: 15px;
    text-align: center;
    position: relative;

    a{
        text-decoration: none;
    }

    .holder-date-logo {
        display: flex;
        justify-content: center;
        margin-bottom: 0;

        .logo-box {
            width: 250px;
            // margin-right: 9px;
        }

        .logo-text {
            font-size: 25px;
        }
    }

    .form-actions{
        display: flex;
        justify-content: center;
    }

}