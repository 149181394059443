@import 'scss/vendors/include-media';

.editable-tag-group {

  .ant-btn {
    margin-left: 10px;
  }

  .edit-tag {
    transition: all 0.3s ease-in-out;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    font-size: 14px;
    font-weight: 600;
    color: var(--white);
    height: 32px;
    padding: 0 8px 0 11px;
    margin: 0;
    border: 1px solid var(--blueSmoke);

    &:hover {
      border-color: var(--lightSteelBlue);

      .tag-text {
        .tag-info {
          opacity: 1;
        }
      }
    }

    &.light-theme {
      color: var(--input_light_colorText);

      .tag-info {
        color: var(--input_light_colorText) !important;
        border-color: var(--input_light_colorText) !important;
      }
    }

    +div {
      @include media('<tablet') {
        transform: translateY(-12px);
      }
    }

    &:nth-child(even)+div {
      @include media('>=tablet') {
        transform: translateY(-12px);
      }
    }

    .tag-text {
      display: flex;
      align-items: center;
      justify-content: center;

      .tag-info {
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--white);
        width: 20px;
        height: 20px;
        margin-left: 10px;
        border-radius: 4px;
        border: 1px solid var(--white);
        opacity: 0;
        transition: all 0.3s ease-in-out;
      }
    }

    .ant-tag-close-icon {
      color: var(--blueSmoke);
      font-size: 18px;
      margin-top: 2px;

      &:hover {
        color: var(--lightSteelBlue);
      }
    }
  }
}