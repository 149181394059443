@import 'scss/base/variables';
@import 'scss/vendors/include-media';
@import 'scss/base/mixins';
@import 'scss/base/helpers';

.page {
    display: flex;
    flex-direction: column;
    flex: auto;

    @include media('>=desktop') {
        margin: 0 31px 30px;
    }

    .page-header {
        padding: 0 15px;
        margin-bottom: 10px;

        @include media('>=desktop') {
            padding: 0px 0px;
        }

        .right-bar {
            display: inline-flex;
            flex-wrap: wrap;
            margin-left: auto;
        }
    }

    .page-header-top-bar {
        margin: 0 0 5px;

        .ant-skeleton-content .ant-skeleton-paragraph > li {
            width: 100% !important;
            height: 28px;
        }

        @include media('>=desktop') {
            display: flex;
            align-items: flex-start;
        }

        h1,
        h2,
        h3 {
            .ant-skeleton-paragraph {
                display: none;
            }
        }

        >* {
            margin-bottom: 13px;
        }

        .right-bar {
            min-width: 100%;
            padding-top: 5px;

            @include media('<desktop') {
                margin: 0 -7px 6px;
            }

            @include media('>=desktop') {
                min-width: 35%;
                justify-content: end;
                margin-bottom: 6px;
            }

            >* {
                margin: 0 7px 7px;

                @include media('<tablet') {
                width: calc(50% - 14px);
                }
            }
        }

        .file-upload {
            .ant-upload.ant-upload-drag{
                display: block;
            }

            .ant-btn {
                width: 100%;
                max-width: 100%;
            }
        }
    }

    .page-navbar {
        display: flex;
        flex-wrap: wrap;

        .page-links {
            display: inline-flex;
            flex-wrap: wrap;
            text-transform: capitalize;
            font-size: 14px;
            font-weight: 600;

            >li {
                margin-right: 18px;

                @include media('>=tablet') {
                    font-size: 16px;
                    margin-right: 25px;
                }

                &.active {
                    a {
                        color: var( --primary-color ) !important;

                        &:before {
                        left: 0;
                        right: 0;
                        visibility: visible !important;
                        opacity: 1 !important;
                        }
                    }
                }

                a {
                    @extend %underline;
                    color: $dark-grey-wp;
                    padding: 0 0 10px;
                    transition: color .3s ease;

                    &:hover {
                        color: $white;
                    }

                    &:before {
                        height: 2px;
                        bottom: -3px;
                        z-index: 1;
                        opacity: 0 !important;
                        visibility: hidden !important;
                    }
                }
            }
        }

        .right-bar {
            >* {
                margin: 0 7px 0;
            }
        }
    }

    .page-content {
        position: relative;
        height: 100%;
        padding: 15px;
        // box-shadow: 0 1px 1px rgba(0,0,0,.04);
        // background: #f6f7f7; 
        // background: var( --background-section-content );
        // border: 1px solid #c3c4c7;
        // border: 1px solid var( --border-color-rgb );
        // border-radius: 6px;


        @include media('>=desktop') {
            // border: 1px solid #c3c4c7;
            // border-radius: 15px;
            padding: 20px 18px;
        }

        @include media('>=1601px') {
            padding: 20px 30px;
        }

        &.tabs-indent {
            margin-top: 38px;
        }

        .col-left {
            @include media('<desktop') {
                order: 2;
            }
        }

        .col-right {
            @include media('<desktop') {
                order: 1;
            }
        }
    }


    .table-action-bar {
        margin-bottom: 20px;
    
        @include media('>=tablet') {
            display: flex;
            align-items: flex-start;
        }
    
        .file-upload {
            .ant-upload.ant-upload-drag {
                display: block;
            }
    
            .ant-btn {
                width: 100%;
                max-width: 100%;
            }
        }
    
        .col {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -7px 10px;
    
            &:last-child {
                @include media('>=tablet') {
                    margin-left: auto;
                }
            }
    
            >* {
                width: calc(50% - 14px);
                margin: 0 7px 0;
    
                @include media('>=tablet') {
                    width: auto;
                }
            }
        }
    
        .table-filter-form {
            width: 100%;
            margin: 0;
    
            @include media('>=tablet') {
                width: auto;
            }
    
    
            >* {
                width: calc(50% - 14px);
                margin: 0 7px 0;
    
                @include media('>=tablet') {
                    width: auto;
                }
            }
        }
    
        .ant-btn {
    
            &.ant-btn-default {
                text-transform: none;
            }
    
            &.ant-btn-link {
                min-width: auto;
                align-items: center;
                text-transform: none;
    
                @include media('<desktop') {
                    border: 1px solid white;
                }
    
                &:hover {
                    @include media('<desktop') {
                        border-color: var( --primary-color );
                    }
    
    
                    span {
                        // color: var( --primary-color ) !important;
                        // border-color: var( --primary-color ) !important;
                        color: var( --primary-second ) !important;
                        border-color: var( --primary-second ) !important;
                    }
                }
    
                span {
                    color: var(--primary-color) !important;
                    line-height: 1.2;
                    transition: all 0.3s ease-in-out;
    
                    @include media('>=desktop') {
                        border-bottom: 1px solid white;
                    }
                }
            }
        }
    }

    .page-navigation{
        display: flex;
        list-style: none;
        padding-left: 5px;
        margin-bottom: 0;
        margin-top: 0;

        li{
            padding: 12px 5px;

            a{
                position: relative;
                font-size: 14px;
                font-weight: 400;
                color: var( --color-text );
                border: 0;
                border-radius: 4px;  
                text-align: center;
                padding: 5px 10px;

                &.selected{

                    font-weight: 600;
                    // color: var( --color-text );
                    color: var(--blueDark2);

                    &:before {
                        left: 0;
                        opacity: 1!important;
                        right: 0;
                        visibility: visible!important;
                        content: "";
                        background-color: var( --blueDark2 );
                        transition: visibility .3s ease-in-out,left .3s ease-in-out,right .3s ease-in-out;
                        position: absolute;
                        z-index: 1;
                        bottom: -9px;
                        height: 2px;
                    }

                }

                &:hover{
                    transition: background .12s ease-out;
                    background: rgba(208,215,222,0.32);
                }

                &.disabled{
                    cursor: not-allowed;
                    opacity: 0.5;
                    // display: inline-block;  /* For IE11/ MS Edge bug */
                    pointer-events: none;
                    text-decoration: none;
                }
                
            }
            
        }


    }

}
