.ant-btn-sm {
    line-height: 1.3;
    align-items: center;
    min-width: 90px !important;
    font-size: 12px;
}

a.ant-btn.ant-btn-primary{
    color: #fff;
    background-color: #1677ff;
    box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
    border: 1px solid transparent;
    border-color: var(--primary-color);
    
    &:hover{
        background-color: #4096ff;
    }
}

.ant-btn {
    // @extend .ellipsis;
    // min-width: 130px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    border-radius: 4px;
    padding: 5px 10px;
    position: relative;
    // top: -3px;
    text-decoration: none;
    // border: 1px solid var( --blueDark2 );
    text-shadow: none;
    font-weight: 600;
    font-size: 13px;
    line-height: normal;
    // color: var( --blueDark2 );
    // background: var( --whiteSmoke );
    cursor: pointer;
    border-color: var(--primary-color);

    &.delete{
        border-color: #ff4d4f !important;
    }

    &.reset-filter{
        border: none !important;
        height: auto !important;
    }

    @include media('<phone') {
        min-width: 90px;
    }

    @include media('<tablet') {
        // display: inline-block;
        font-size: 12px;
        padding: 8px 10px;
    }

    >span {
        @include media('<phone') {
        display: inline;
        }
    }

    .anticon {
        font-size: 21px;

        @include media('<phone') {
            font-size: 16px;
        }

        @include media('<tablet') {
            font-size: 14px;
        }

        &.anticon-plus {
            vertical-align: middle;
            margin-top: 2px;
            font-size: 18px;
        }
    }

    .arrow-down {
        transition: transform 0.3s;
        margin-right: -6px;
    }
}

.ant-btn-default {
    // color: white !important;
    border-color: var( --border-color-rgb );

    &:hover {
        border-color: var( --primary-color );
        border-color: var( --primary-color );
    }

    &:focus {
        border-color: var( --primary-color );
    }

    &[disabled] {
        background-color: transparent;
        color: darken(white, 40%) !important;

        &:hover {
        background-color: transparent;
        }
    }
}

    a.ant-btn {
    // padding-top: 5px !important;
    line-height: 1.2 !important;

    .anticon-plus {
        margin-right: 6px;
    }
    }

.ant-btn-primary {
    &:hover {
        background-color: var( --primary-color );
        border-color: var( --primary-color );
        color: white;
    }

    &:focus {
        background-color: var( --primary-color );
    }

    &[disabled] {
        background-color: #013b7d;
        color: white;

        &:hover {
            background-color: #013b7d;
            color: white;
        }
    }
}

.ant-btn-secondary {
    
    // background-color: var( --secondary-color );
    // border-color: var( --secondary-color );

    background-color: #7a7f84;
    border-color: #7a7f84;
    color: white;
    

    &:hover {
        background-color: #8D9296;
        border-color: #8D9296;
    }

    &:focus {
        background-color: #6D7276;
        border-color: #6D7276;
    }

    &[disabled] {
        background-color: #424548;
        color: var( --darkGrey ) !important;

        &:hover {
        background-color: #424548;
        }
    }

    &.bg-light-gray {
        color: var( --darkGrey )  !important;
        background-color: rgba(34, 43, 51, 0.15);
        border-color: transparent;

        &:hover {
        background-color: rgba(34, 43, 51, 0.20);
        }
    }
}

.btn-add {
    display: inline-block;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    color: var( --primary-color );
    transition: color 0.3s;
    margin-bottom: 13px;

    &:hover {
        color: lighten(#135e96, 5%);
    }
}


.page-content {

    a.link {
        color: var( --primary-color );
        align-items: center;
        display: inline-flex;
        justify-content: center;
        border: 0;
        top: 0;
    }

}
