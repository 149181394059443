@import 'scss/vendors/include-media';

.sidebar-menu {
    // font-weight: 600;
    text-transform: capitalize;
    padding: 10px 0;
    margin-bottom: 20px;

    .ant-menu-submenu-arrow{
        color: var( --color-text );
    }

    a {
        font-size: 14px;
        color: var( --color-text ) !important;
        text-transform: initial;
        font-weight: 500;
    }

    .anticon {
        color: inherit !important;
        font-size: 19px !important;
        margin-right: 15px;
        transition: none !important;
    }

    .ant-menu-item-only-child {
        display: inline-block;
        // margin-left: 0 !important;
        // margin-right: 0 !important;
        margin-bottom: 10px !important;

        &.border-menu-item {
            margin-top: 47px !important;
            border: solid var( --primary-color );
            border-width: 1px 0  1px 0;

            &.ant-menu-item-selected {
                background-color: var( --primary-color ) !important;
            }
        }

        a {
            display: inline-flex;
            align-items: center;
            margin-top: 5px;
        }
    }

    .ant-menu-item-active,
    .ant-menu-item-selected,
    .ant-menu-submenu-title:active{
        background-color: rgba(0, 0, 0, 0.06) !important;
    }
    
}

.ant-menu.ant-menu-sub.ant-menu-inline{
    background-color: var(--whiteGreyLight) !important;
}

.ant-layout-sider-zero-width-trigger {

    @include media('<widescreen') {
        top: 0px !important;
    }
}

.ant-layout-sider {

    @include media('<widescreen') {
        position: fixed !important;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 1001;
    }
}