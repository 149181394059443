// @import 'scss/base/variables';
@import 'scss/vendors/include-media';


.top-bar {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 12px 15px 12px 0px;
    margin-bottom: 16px;
    // background: white;
    background: var( --whiteGreyLight ); 
    // border-bottom: 1px solid #c3c4c7;
    border-bottom: 1px solid var( --border-color-rgb );
    // box-shadow: 0 1px 1px rgba(0, 0, 0, 0.04);

    @include media('>=tablet') {
        padding: 12px 30px;
    }

    @include media('>=1200px') {
        padding: 12px 33px;
    }

    .ant-breadcrumb  a {
        // color: $dark-grey-wp;

        &:hover{

            // color: $primary-color ;
        }
    }

    .ant-breadcrumb li:last-child a {
        // color: $dark-grey-wp;
    }

    .anticon svg {
        // color: $dark-grey-wp;
    }

    a{
        // color: $dark-grey-wp;

        &:hover{

            // color: $primary-color ;
        }
    }

}

.ant-layout-sider-children{
    .anticon svg {
        color: var( --color-text );
    }
}

.nav-opener{
    .anticon svg {
        color: white;
    }
}

.nav-opener {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: white;
    min-width: 30px;
    height: 30px;
    font-size: 18px;
    background-color: #2F373F;
    margin: -5px 10px 0 0;
    border: solid rgba(255, 255, 255, 0.2);
    border-width: 1px 1px 1px 0;
    border-radius: 0 4px 4px 0;
}

.nav-opener.absolute {
    position: absolute;
    right: -40px;
    top: 13px;
}

@media (min-width: 1200px){
    .nav-opener {
        display: none;
    }
}
