$white: #ffffff;
$dark-grey: #2F373F;
$darken-grey: #3B424A;
$grey: #424A51;
$light-gray: #7A7F84;
$lighten-gray: #BDBFC2;
$lightening-gray: #F3F4F4;
$magenta: #E5027D;
$charleston-green: #222B33;
$red: #E84A4D;
$green: #44D13F;
$green-2: #0FC949;
$yellow: #F8DC00;

// //group colors
$orange: #F49A00;
$ligth-green: #78CB93;
$light-blue: #5FECF9;
$pink: #F64DF7;
$dark-pink: #E82790;
$dark-yellow: #D6BD00;

// $primary-color: $magenta;

$wp-link-href: #135e96;
$button-bg-hover-wp: #135e96;
$blue-wp: #2271b1;
$light-white-wp: #f6f7f7;
$dark-grey-wp: #23272d;
$lighten-gray-wp: #646970;
$gray-middle-wp: #c3c4c7;
$black-middle-wp: #2c3338;
$primary-color: #72aee6;
$wp-body-black: #3c434a;

//heading
$font-weight-heading: 600;
$line-height-heading: 1.2;
$margin-bottom-heading: 10px;
