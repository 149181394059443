.ant-table {
    
    line-height: 1.2;

    .icon-delete {
        font-size: 16px;
        font-weight: 600;
    }

    table{
        // font-size: 13px;
        // border: 0px solid #c3c4c7 !important;
        // background: var(--background-section-content) !important;
        // border-radius: 0px;
        // border-bottom: 1px solid #dddddd !important;
        
        // > thead > tr:first-child >*:first-child{
        //     border-start-start-radius: 0px !important;
        // }

        // > thead > tr:first-child >*:last-child {
        //     border-start-end-radius: 0px !important;
        // }

        border: 1px solid #dddddd;
        // background: white;
        border-radius: 6px;
        color: #1d2327;
    }

    &.ant-table-middle {

        font-size: 13px !important;

        @include media('<tablet') {
            min-width: 1100px !important;
        }

        @include media('<desktop') {
            min-width: 1300px;
            font-size: 12px !important;
        }

        .table-stands & {
            @include media('<1600px') {
                min-width: 1300px;
                font-size: 12px !important;
            }
        }

        @include media('<1800px') {
            font-size: 13px !important;
        }

    }

    a {
        color: var( --primary-color );
        font-weight: 600;
    }

    th.ant-table-cell {
        white-space: nowrap;
        font-weight: 600;
        // padding-left: 10px !important;
        // padding-right: 10px !important;
        padding: 7.5px 5px !important;
        // border-radius: 0 !important;

        @include media('>=1601px') {
            padding-left: 20px !important;
            padding-right: 20px !important;
        }

        .ant-table-column-title {
            // @extend .ellipsis;
        }
    }

    td.ant-table-cell {

        padding: 7.5px 5px !important;

        @include media('>=1601px') {
            padding-left: 20px !important;
            padding-right: 20px !important;
        }

        &:last-child {
            padding: 0 !important;
        }
    }

    .ant-table-column-sorter {
        color: #484B4D;

        .ant-table-column-sorter-up,
        .ant-table-column-sorter-down {
            font-size: 10px;
        }
    }

    .ant-empty-normal {
        // color: $white;
    }

    .ant-table-tbody {
        >tr {
            &.ant-table-placeholder {
                &:hover {
                    >td {
                        background-color: transparent;
                    }
                }
            }
        }
    }

    .ant-table-selection-column {

        .ant-checkbox-wrapper {
            position: relative;
            display: flex;
            align-items: center;
            font-size: inherit;
            z-index: 1;

            .ant-checkbox-checked {

                .ant-checkbox-inner {
                    // background-color: $blue-wp !important;
                    // border-color: $button-bg-hover-wp;
                }

            }

            .ant-checkbox-inner {
                background-color: transparent;
                border: 1px solid #c3c4c7;
            }

            .ant-checkbox {
                top: 2px;

                +span {
                    margin-left: 6px;
                }
            }
        }
    }

    .ant-table-thead{
        border: 1px solid #dddddd;

        th.ant-table-column-has-sorters:hover {
            background: #f0f0f1;
            // color: $dark-grey-wp;
        }

        th:before{
            background-color: rgba(0, 0, 0, 0.06) !important;
        }

    }
    .ant-table-thead > tr > th {
        position: relative;
        color: #50575e;
        font-weight: 400;
        text-align: left;
        // background: #f6f7f7;
        background: var( --whiteGreyLight3 );
        border-bottom: 1px solid #dddddd;
        transition: background 0.3s ease;
    }

    .ant-table-tbody > tr{
    
        &:nth-of-type( even ) {
            background-color: #f6f7f7;
        }
        

        & > td {
            border-bottom: none;
            transition: background 0.3s;
        }

    }

    .ant-table-tbody > tr.ant-table-row:hover > td {
        background: #e6f4ff;

        .ant-checkbox-inner {
            // border: 1px solid $white;
        }
    }

    .ant-table-tbody > tr.ant-table-row-selected > td {
        background: #e6f4ff;
        border-color: rgba(0, 0, 0, 0.03);
    }

    td.ant-table-column-sort {
        background: #e6f4ff;
    }

    .ant-table-thead >tr>td {
        background: var(--whiteGreyLight3);
        border-bottom: 1px solid #dddddd;
        border-top: 1px solid #dddddd;
    }

}

