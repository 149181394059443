$breakpoints: ('xs-phone': 320px,
    'phone': 480px,
    'tablet': 768px,
    'desktop': 1024px,
    'widescreen': 1200px);

// keywords
$media-expressions: ('screen': 'screen',
    'print': 'print',
    'handheld': 'handheld',
    'landscape': '(orientation: landscape)',
    'portrait': '(orientation: portrait)',
    'retina2x': '(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)',
    'retina3x': '(-webkit-min-device-pixel-ratio: 2.5), (min-resolution: 240dpi)'
);

@import 'vendors/include-media';

// @import 'base/variables';
@import 'base/functions';
@import 'base/mixins';
@import 'base/helpers';
@import 'base/forms';
@import 'base/buttons';
@import 'base/table';

@import 'layout/grid';

//global app styles


:root {
    //colors
    --charlestonGray: #121f29;
    --darkBlue: #262e37;
    --darkGrey: #222b34;
    --gunmetal: #363d45;
    --darkGunmetal: #2a323a;
    --blueSmoke: #656f78;
    --stormGray: #888d95;
    --lightSteelBlue: #aaaeb1;
    --silverChalice: #cacbcd;
    --lightGray: #e5e5e5;
    --whiteSmoke: #f2f2f2;
    --whiteGreyLight3: #f0f2f5;
    --whiteGreyLight2: #fbfafd;
    --whiteGreyLight1: #f6f7f7;
    --whiteGreyLight: #f6f8fa;
    --white: #fff;
    --dodgerBlue: #4471ff;
    --cornflowerBlue: #4f80fe;
    --blue: #4096ff;
    --skyBlue: #3d65e5;
    --royalBlue: #5f86ff;
    --blueDark: #135e96;
    --blueDark2: #2271b1;
    --blueMiddle: #1f75cb;
    --blueMiddleLight: #72aee6;
    --red: #e84a4d;
    --yellow: #f8dc00;
    --green: #44d13f;
    --emeraldGreen: #0fc949;
    --limeGreen: #45ff1b;
    --darkOrange: #ff8c14;
    --cyan: #1bffef;
    --electricPink: #f01df1;
    --mediumPurple: #8759ff;

    --primary-color: var( --blueDark );
    --primary-second: var( --blue );
    --secondary-color: var( --blueMiddle);
    --success-color: var( --green );
    --info-color: var( --cyan );
    --warning-color: var( --yellow );
    --danger-color: var( --red );
    --color-text: #3c434a;

    --background-section-content: var( --whiteGreyLight2 );
    --border-color-rgb: rgb(216, 222, 228);

    --font-weight-heading: 600;
    --line-height-heading: 1.2;
    --margin-bottom-heading: 10px;

    --second_font-family: "Saira", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
    --token_font-family: 'Titillium Web',
        'Segoe UI',
        Roboto,
        'Helvetica Neue',
        Arial,
        'Noto Sans',
        sans-serif,
        'Apple Color Emoji',
        'Segoe UI Emoji',
        'Segoe UI Symbol',
        'Noto Color Emoji';
}

#root {
    overflow-x: hidden;
}

#root,
.app,
.app-layout {
    height: 100%;
    min-height: 100vh;
}

body{
    color: var( --color-text );
    margin: 0;
    font-family: var( --token_font-family ) !important;
}

#main {
    display: flex;
    flex-direction: column;
    flex: auto;
}

.lang-rtl{

    .he{
        unicode-bidi: bidi-override;
        direction: rtl;
    }
    
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: var( --font-weight-heading );
    line-height: var( --line-height-heading );
    color: var( --margin-bottom-heading );
    margin-top: 0;
}

h1 {
    font-size: 18px;

    @include media('>=desktop') {
        font-size: 21px;
    }
}

h2 {
    font-size: 17px;

    @include media('>=desktop') {
        font-size: 18px;
    }
}

h3 {
    font-size: 16px;

    @include media('>=desktop') {
        font-size: 17px;
    }
}

h4 {
    font-size: 16px;

    @include media('>=desktop') {
        font-size: 16px;
    }
}

ul {
    // @extend %list-reset;
}

img {
    max-width: 100%;
    height: 20px;
}

.underline-none {
    text-decoration: none !important;
}

.link-text{
    a{
        color: var( --color-text );
        &:hover{
            color: var(--primary-color);
        }
    }
}

.important-text{
    &::before {
        display: inline-block;
        margin-inline-end: 4px;
        color: #ff4d4f;
        font-size: 14px;
        font-family: SimSun,sans-serif;
        line-height: 1;
        content: "*";
    }
}

.loader-main {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.date-time {
    font-size: 13px;
    white-space: nowrap;
    color: var( --color-text ) !important;
    margin-top: 15px;
    margin-bottom: 2px;
    font-weight: 600;
    text-align: center;
    // letter-spacing: 1px;
}

.nav-opener {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: white;
    min-width: 30px;
    height: 30px;
    font-size: 18px;
    background-color: var( --darkGrey );
    margin: -5px 10px 0 0;
    border: solid rgba(white, .2);
    border-width: 1px 1px 1px 0;
    border-radius: 0 4px 4px 0;

    @include media('>=1200px') {
        display: none;
    }

    &.absolute {
        position: absolute;
        right: -40px;
        top: 13px;

    }
}

.ant-menu.ant-menu-dark,
.ant-layout-sider {
    background: var(--whiteGreyLight) !important;
}

.ant-result-title,
.ant-result-subtitle {
    color: var( --darkGrey );
}

// antd global styles

.ant-layout-sider-children {
    position: relative;
}

.ant-notification {

    .ant-notification-notice-message,
    .ant-notification-notice-description {
        color: var( --darkGrey );
    }
}

.ant-dropdown-arrow {
    border-radius: 0;
}


.holder-date-logo {
    padding: 0px 2px 0px 2px;
    margin-bottom: 5px;
}

.logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    // background-color: rgba(0, 0, 0, 0.06);

    // .logo-box {
    //     margin-right: 12px;
    // }

    .logo-img{
        @include media('<tablet') {
       
            display: none;
            
        }
    }

    .logo-text {
        font-size: 18px;
        color: var( --color-text );
        // margin-top: 15px;
        font-weight: 700;
        // padding-bottom: 10px;
        border-bottom: 1px solid var(--border-color-rgb);
        // @include media('<tablet') {
       
        //     display: none;
            
        // }
    }

    .logo-text-sub {
        color: rgba(0,0,0,.45);
        font-weight: 500;
    }
    
}

//global tables styles

.no-data-box {
    position: relative;
    padding-bottom: 28px;
}

.ant-table-empty {
    .ant-empty-description {
        display: none;
    }

    .no-data-text {
        position: absolute;
        left: 50%;
        bottom: 26px;
        transform: translateX(-50%);
        color: var( --darkGrey );
    }
}

.ant-table-wrapper {
    overflow-x: auto;
}

.checkbox-outline {
    display: flex;
    align-items: center;
    min-width: 150px;
    font-size: inherit;

    .ant-checkbox-checked {

        .ant-checkbox-inner {
            background-color: var( --primary-color ) !important;
            border-color: var( --primary-color );
        }
    }

    .ant-checkbox-inner {
        background-color: transparent;
        border-color: white;
    }

    .ant-checkbox {
        top: 0;

        +span {
            margin-left: 6px;
        }
    }
}

.ant-pagination.mini {
    font-size: 12px;

    li.ant-pagination-item {
        margin-right: 3px;

        &:hover {
            background: #4D545B !important;
        }

        a {
            color: white;
        }
    }

    .ant-pagination-item-ellipsis {
        color: white;
    }

    .ant-pagination-prev,
    .ant-pagination-next {
        &:hover {
            .ant-pagination-item-link {
                color: white;
                background: #4D545B;
            }

        }
    }
}

.table-skeleton {
    display: block;
    border-radius: 6px;
    height: 17px !important;

    &.one-quarter {
        width: 25%;
    }

    &.half {
        width: 50%;
    }

    &.three-quarters {
        width: 75%;
    }

    &.full {
        width: 100%;
    }

    span {
        width: 100% !important;
        height: inherit !important;
    }

    &+.table-skeleton {
        margin-top: 10px;
    }
}

// links

.ant-dropdown-open {
    .arrow-down {
        transform: rotate(180deg) translateY(3px);
    }
}

.ant-dropdown {

    .ant-dropdown-menu {
        padding: 10px 0;
    }


    .ant-dropdown-menu-item,
    .ant-dropdown-menu-submenu-title {
        font-size: 15px !important;
        color: var( --darkGrey );
        padding: 2px 20px;
    }

    .ant-dropdown-menu-item {

        .anticon{
            margin-right: 8px;
        }

        &:hover {
            color: var( --primary-color );
            background-color: transparent;
        }
    }
}


//global tab styles


.ant-layout{
    background-color: white;
    // background-color: var( --whiteGreyLight3 );
}

.permalink-link{
    text-transform: lowercase;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #e6f7ff;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.13), 0 0 0 0px rgba(0, 0, 0, 0.07) !important;
    outline: aliceblue;
    border-radius: 4px;
    border: 1px solid #c5c5c5;
}

.ant-tag{

    &.ant-tag-default{
        border: 1px solid #d9d9d9;
        color: rgba(0, 0, 0, 0.88);
    }

}

.form-btn-holder {
    display: flex;
    padding: 10px 0 10px;

    @include media('>=tablet') {
        padding: 38px 0 20px;
    }

    .ant-btn {

        &:first-child {
            margin-right: 5px;
        }

    }

    .btn-right {
        margin-left: auto;
    }
    
}

.drawer-standard .ant-drawer-wrapper-body {
    background-color: var(--whiteGreyLight3);
}

.modal-form.filters .modal-title {
    text-align: left;
}

.modal-form .modal-title {
    display: block;
    margin-bottom: 25px;
}

.drawer-standard .modal-title {
    font-size: 14px;
    font-weight: 600;
}

.drawer-standard .ant-drawer-close {
    position: absolute;
    right: 0;
    top: 19px;
}

.drawer-standard .ant-drawer-header {
    background: transparent;
    left: 0;
    position: absolute;
    right: 0;
    border: 0;
    padding: 16px 17px;
}

.drawer-standard .ant-drawer-body {
    padding: 55px 18px 18px;
}

.ant-drawer-body .ant-form-item-row{
    flex-direction: column;
}

.ant-form-item-row .ant-form-item-label {
    text-align: left;
}

.ant-form-vertical{
    padding: 0;
}

.filter-count {
    background-color: #1677ff;
    border-radius: 50%;
    display: inline-block!important;
    font-size: 12px;
    height: 20px;
    line-height: 20px;
    margin-left: 10px;
    margin-top: 1px;
    width: 20px;
    color: white;
}

.modal-standard {

    &.modal-delete{

        .ant-modal-close {
            top: 7px;
        }

        .ant-modal-footer{

            display: flex;
            justify-content: space-between;
        }
        
    }

}

.ant-tooltip { 

    --antd-arrow-background-color: white;

    .ant-tooltip-inner {
        min-width: 32px;
        min-height: 32px;
        padding: 6px 8px;
        text-align: start;
        text-decoration: none;
        word-wrap: break-word;
        background-color: white;
        border: 1px solid transparent;
        border-radius: 4px;
        border-color: var(--border-color-rgb);
        border-bottom: none;
        box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
        box-sizing: border-box;
        color: rgba(0, 0, 0, 0.88);
    }

}

.ant-dropdown-trigger{

    &.btn-more{
        cursor: pointer;
    }
    
} 

.tag-link{

    padding-bottom: 2px;

    &:hover{
        border-color: var(--blueDark2);
        // background: var(--blueDark2);
        // box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
    }

    a{
        &:hover{
            color: var(--blueDark2);
        }
    }
}

.ant-select-item-group {
    font-size: 13px !important;
    color: var(--color-text) !important;
    font-weight: bold !important;
}

.schema-radio-select{
    .ant-radio-wrapper span.ant-radio+*{
        width: 100%;
    }

    // .ant-radio-wrapper span.ant-radio+*
}

.view-box-field {
    display: flex;
    align-items: center;
    font-weight: 600;

    &:hover {
        .btn-view {
            opacity: 1;
        }
    }

    .count {
        margin-right: 5px;
    }

    .btn-view {
        font-size: 12px;
        // color: white !important;
        text-transform: uppercase;
        //background-color: #585E64;
        transition: opacity .2s ease-in-out;
        border-radius: 4px;
        margin-left: 3px;
        padding: 2px 13px;
        opacity: 0;
        border: 1px solid;
        border-color: var(--border-color-rgb);
        background-color: #ffffff;
        color: rgba(0, 0, 0, 0.88);
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
    }
}

.field-empty,
.no-translate{
    color: rgba(0,0,0,.45);
}

.field-status{
    border-radius: 13px;
    padding: 5px 10px;
    text-transform: capitalize;
    font-size: 14px;
    letter-spacing: 0;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
    color: var( --color-text );

    &.preview{
        color: #036AD6;
        background-color: rgba(3,106,214,0.15);
    }

    &.on_sale {
        color: #0A8716;
        background-color: rgba(10,135,22,0.15);
    }

    &.draft {
        color: #FF9402;
        background-color: rgba(255,148,2,0.15);
    }

    &.closed {
        background: var(--whiteGreyLight3);
    }

    &.trash{
        background: #fff2e8;
        color: #d4380d;
    }
}

.ant-modal-content{

    ::-webkit-scrollbar {
        width: 10px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
        background: transparent;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #D8D8D8;
        border-radius: 10px;
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: darken(#D8D8D8, 10%);
    }

    .ant-radio-wrapper{
        margin-inline-end: 0px;
    }
    
}

// .place-date-form{
//     .field-date{
//         &.time{

//             .ant-form-item-control-input-content{
//                 display: flex;
//             }
//         }
//     }
// }
