@import 'scss/vendors/include-media';
@import 'scss/base/variables';


.ant-breadcrumb {

    text-transform: inherit !important;

    a{
        color: var( --color-text );
    }
    

    &.main-breadcrumb {
        text-transform: capitalize;
        margin-right: 10px !important;

        @include media('<tablet') {
            font-size: 12px;
        }

        ol {
            align-items: center;
        }

        li:last-child a {
            font-weight: 600;
            cursor: default;
        }
        
    }
}